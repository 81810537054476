import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from '@rails/request.js'
import { Base64 } from "js-base64";

import ReactDOM  from "react-dom/client";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-creator-core"
import toastr from "toastr";
import { slk } from "survey-core";

import "../../widgets/likert"
import "../../widgets/likertMatrix"
import "../../widgets/noUiWidget"
import "../../widgets/noUiWidget-Custom"
import "../../widgets/noUiWidget-TLX"
// import widgets

// import likert from "../../widgets/likert";
// import likertMatrix from "../../widgets/likertMatrix";
// import { registerLikert } from '../../widgets/QuestionLikertModel';

// Localization
import { editorLocalization } from "survey-creator-core";
import "survey-creator-core/i18n/french";
import "survey-creator-core/i18n/english";
import "survey-creator-core/i18n/dutch";
import "survey-creator-core/i18n/portuguese";

// Possible build fix
// https://github.com/vitejs/vite/discussions/7938


// registerLikert();

export default class extends Controller {
  static targets = ["creator", "notice"];

  static values = {
    content: String,
    id: Number,
    locale: String,
    url: String,
    writable: Boolean,
    updateMessage: String,
    saveErrorMessage: String,
  };

  connect() {
    this.loadingText = this.creatorTarget.innerHTML;
    // Default toastr options
    toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: true,
      progressBar: true,
      positionClass: "toast-bottom-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };

    this.loadEditor();
  }

  disconnect() {
    this.creatorTarget.innerHTML = this.loadingText;
  }

  async loadEditor() {

    let options = {
      showEmbededSurveyTab: false,
      showTestSurveyTab: true,
      showJSONEditorTab: true,
      showOptions: true,
      // showTranslationTab: true, // TODO: Discuss this in peac²h group.
      pageEditMode: "bypage",
      isAutoSave: !this.writableValue,
      questionTypes: [
        "text",
        "multipletext",
        "checkbox",
        "radiogroup",
        "dropdown",
        "comment",
        "rating",
        "imagepicker",
        "boolean",
        "image",
        "html",
        "ranking",
        //   "file",
        "expression",
        "matrix",
        "matrixdropdown",
        //"matrixdynamic",
        // "panel",
        // "paneldynamic",
        "expression",
        "multipletext",
        "signaturepad",
        "nouislider",
        "nouislidertlx",
        "nouislidercustom",
        "likert",
        "likertmatrix",
      ],
    };

    if (this.hasLocaleValue) {
      editorLocalization.currentLocale = this.localeValue;
    }else {

      editorLocalization.currentLocale = "en";
    }

    // TODO: Force single survey locale
    // SurveyCreator.surveyLocalization.supportedLocales = ["en"];

    const surveyCreator = new SurveyCreator(options);

    slk(process.env.SURVEY_LICENSE_KEY);
    // window.Survey = surveyCreator.survey; 

    this.creator = surveyCreator;

    // Not working on creator... It should be added to Surveys. 
    // surveyCreator.onClearFiles.add(async (_, options) => { 
    //   console.log("CLEAR FILE", options);
    // });

    // TODO: For the viewer, it should upload to the result routes.
    surveyCreator.onUploadFile.add(async (_, options) => { 

    options.files.forEach(async (file) => {
      const formData = new FormData();
      formData.append("file", file);
      console.log("Posting file: ", file)
      const request = new FetchRequest('post', 
        "/survey_contents/" + this.idValue + "/attach", 
        {
          body: formData
        })
      const response = await request.perform();
      if (response.ok) {
        options.callback(
          "success",
          await response.text
          // A link to the uploaded file                
      );
      }else {
      alert(response);
      }
    }); 
    
      // options.files.forEach((file) => {

      //   let reader = new FileReader();
      //   // reader.readAsText(file);
      //   reader.onload = async () => {
      //     let data = [this.idValue, file.name, btoa(reader.result)];
      //     console.log("Read: ", btoa(reader.result));


      //   };
      //   reader.readAsDataURL(file);
      // });

     
      // options.files.forEach(function (file) {
      //     formData.append(file.name, file);
      // });
      // fetch("https://api.surveyjs.io/private/Surveys/uploadTempFiles", {
      //     method: "post",
      //     body: formData
      // }).then((response) => response.json())
      //     .then((result) => {
      //         options.callback(
      //             "success",
      //             // A link to the uploaded file
      //             "https://api.surveyjs.io/private/Surveys/getTempFile?name=" + result[options.files[0].name]
      //         );
      //     })
      //     .catch((error) => {
      //         options.callback('error');
      //     });
    });



    if (this.writableValue) {
      surveyCreator.saveSurveyFunc = this.saveMySurvey.bind(this);
    }

    let surveyContent = Base64.decode(this.contentValue);

    // TODO: Try to get back the order / categories
    this.creator.toolbox.changeCategories([
      {
        name: "paneldynamic",
        category: "Panels",
      },
      {
        name: "html",
        category: "Expert",
      },
      {
        name: "imagepicker",
        category: "Expert",
      },
      {
        name: "image",
        category: "Expert",
      },
      {
        name: "expression",
        category: "Expert",
      },
      {
        name: "signaturepad",
        category: "Expert",
      },
    ]);

    try {
      this.creator.JSON = JSON.parse(surveyContent);
    } catch (e) {
      // TODO: display the JSON file ?
      console.log("Loading failed");
    }

    let root = ReactDOM.createRoot(this.creatorTarget);
    root.render(<SurveyCreatorComponent creator={this.creator} />);
  }

  async saveMySurvey() {
    let yourNewSurveyJSON = JSON.stringify(this.creator.JSON);
    
    // empty form initialization for Ajax submission (HTML/JS standard)
    let data = {};

    // ## TODO: think about adding security token
    // Set the new position to update
    data["id"] = this.idValue;
    data["content"] = yourNewSurveyJSON;

    // TODO: UPDATE IN PROGRESS
    const request = new FetchRequest('patch', this.urlValue, {
      body: JSON.stringify(data)
    })
    const response = await request.perform();
    if (response.ok) {
      toastr["success"](this.updateMessageValue);
    }else {
      toastr["error"](this.saveErrorMessageValue);
    }
  }
}
