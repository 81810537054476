// import Sortable from 'sortablejs';
import { FetchRequest } from '@rails/request.js'
import { Controller }from "@hotwired/stimulus"
// Sortable List
// This is used for drag'n drop
// in ExperimentalStudy mostly

export default class extends Controller {
  static targets = [ "creator" ]

  connect() {
    this.initSortable();
  }

  async initSortable() {
    const { default: Sortable, MultiDrag } = await import('sortablejs');

    var sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
      // filter: ".no-drag",
      animation: 200,
      handle: ".handle",
      // draggable: ".draggable", // Specifies which items inside the element should be draggable
    });
  }

  end(event){
    let url = this.data.get("url");
    let id = event.item.dataset.itemId;
    let id2 = event.item.dataset.itemId2;

    // // empty form initialization for Ajax submission (HTML/JS standard)
    // let data = new FormData();

    // // Set the new position to update
    // data.append("position", event.newIndex);

    let data = {position: event.newIndex};

    // used in nested resources
    url = url.replace(":id2", id2);
    url = url.replace(":id", id);

    // TODO: Use stimulate instead of AJAX in the future ?
    // Fire the update

    console.log(url, data); 
    const request = new FetchRequest('patch', url, {
      body: JSON.stringify(data)
    })

    request.perform();
    
    // const response = await request.perform();
  
  }

}
