import { Controller }from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

// Not sure if still used
// To check in survey/_large_preview
// TODO: rename to reveal_controller 
// Only one method & target for now

export default class extends Controller {
  // TODO: element is non-discriptive. Refactor to "ToReveal" or "HiddenAtLoad" 
  static targets = [ "element"]

  connect() {
  }

  display(){
    this.elementTargets.forEach( (element) => {
      element.classList.remove("d-none");
      element.classList.remove("hidden");
    });
  }

  toggleDisplay(){
    this.elementTargets.forEach( (element) => {
      element.classList.toggle("hidden");
    });
  }
  
  toggleCollapse(e){
    let currentElement = e.currentTarget.parentNode.parentNode;
    console.log(currentElement)
    this.elementTargets.forEach( (element) => {
      if(element===currentElement || element===currentElement.parentNode.parentNode)
        element.classList.toggle("group-collapse");
      /*
      if(element===currentElement || element===currentElement.parentNode.parentNode)
      {
        element.classList.toggle("groupeCollapse");
        if(element.classList.contains("groupeCollapse")){
          let target = element.getElementsByClassName("group-name")[0];
          let toAdd = e.currentTarget

          var parentSpan = target.parentNode;
          parentSpan.insertBefore(toAdd, target);
          console.log(target)
          console.log(toAdd)
          console.log(parentSpan)
        }
        else{
          console.log("hydo")
        }
      }
      */
    });
  }

}
