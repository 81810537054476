import { Controller } from "@hotwired/stimulus";

// Please match variables.css, which is the source of truth for colors.

export default class extends Controller {
  static values = {
    storage: String,
  };

  static targets = ["input"];

  connect() {
    let isEnabled = localStorage.getItem(this.storageValue) == "enabled";
    if (isEnabled) {
      // This cause flickers
      // this.enable();
      this.inputTarget.checked = true;
    }

    if (this.storageValue == "night-mode") {
      // TODO: Browser can detect this !
      const prefersDarkScheme = window.matchMedia(
        "(prefers-color-scheme: dark)"
      );
      // console.log("Night auto? : ", prefersDarkScheme);
    }
  }

  enable() {
    if (this.storageValue == "night-mode") {
      window.document
        .getElementsByTagName("html")[0]
        .classList.add("dark-theme");
    }

    if (this.storageValue == "app-mode") {
      window.document
        .getElementById("custom-deploy-style")
        .setAttribute("disabled", true); // .remove();
    }
    localStorage.setItem(this.storageValue, "enabled");
  }

  disable() {
    if (this.storageValue == "night-mode") {
      window.document
        .getElementsByTagName("html")[0]
        .classList.remove("dark-theme");
    }
    if (this.storageValue == "app-mode") {
      window.document
        .getElementById("custom-deploy-style")
        .removeAttribute("disabled"); // .remove();
    }
    localStorage.setItem(this.storageValue, "disabled");
  }

  toggle() {
    if (this.inputTarget.checked) {
      this.disable();
    } else {
      this.enable();
    }
  }
}
