import { Controller }from "@hotwired/stimulus"

// Use Turbo better like this ?!
// https://gorails.com/episodes/custom-turbo-stream-actions-and-auto-submitting-forms

export default class extends Controller {
  
  static targets = [ "form" ];
  
  submit(event){
    event.preventDefault();
    this.formTarget.requestSubmit();//  "submit")
  }

  submitHTML(){
    this.formTarget.requestSubmit();//  "submit")
  }


}
