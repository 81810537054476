import { Controller }from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

// Download controller
// Actions to trigger browser download from JSON or text (CSV)

export default class extends Controller {

  connect() {
    this.url = this.data.get("url");
    this.name = this.data.get("name");
  }

  async saveData(event) {
    // Skip the default button click behaviour
    event.preventDefault();


    const request = new FetchRequest('get', this.url, {
      body: JSON.stringify(data)
    })
    const response = await request.perform();
    if (response.ok) {
      const body = await response.text

      this.openSaveFileDialog(JSON.stringify(body), this.name);      
    }

  }

  openSaveFileDialog(data, filename, mimetype) {
    if (!data) return;

    var blob = data.constructor !== Blob
      ? new Blob([data], {type: mimetype || 'application/octet-stream'})
      : data ;

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
      return;
    }

    var lnk = document.createElement('a'),
        url = window.URL,
        objectURL;

    if (mimetype) {
      lnk.type = mimetype;
    }

    lnk.download = filename || 'untitled';
    lnk.href = objectURL = url.createObjectURL(blob);
    lnk.dispatchEvent(new MouseEvent('click'));
    setTimeout(url.revokeObjectURL.bind(url, objectURL));
  }

}
