import { Controller } from "@hotwired/stimulus";

/**
 * Active Item controller enables to display 
 * and hide elements according to their ids.
 *
 */
export default class extends Controller {
  
  static values = { visible: Boolean, id: Number, subject: Number };

  connect() {
  }


  show(){
    this.element.classList.remove("hidden");
  }

  hide(){
    this.element.classList.add("hidden");
  }

  refresh(id){
    let isHidden = this.element.classList.contains("hidden")

    // it should be visible
    if(id == this.idValue){
      if(isHidden){
        this.show();
      }
    } else {
      // Should not be visible
      if(!isHidden){
        this.hide();
      }
    }
  }

  refreshSubject(id, subject){
    let isHidden = this.element.classList.contains("hidden")
    

    // Si ID est bon (bonne TP)  
    // et si Subject est bon (Bonne carte)
    
    // -> Affiche 
    if( subject != this.subjectValue){
      return;
    }

    // it should be visible
    if(id == this.idValue){
      if(isHidden){
        this.show();
      }
    }else {
      if(!isHidden){
        this.hide();
      }
    }
  }

  name() {
    let id = this.idValue;
    return "active-item-" + id;
  }

}
