//// Cookie consent library from github:
// https://github.com/orestbida/cookieconsent
import "vanilla-cookieconsent/dist/cookieconsent.js";

if (!(process.env.RAILS_ENV === "development" || process.env.RAILS_ENV === "test")) {
// if(true){
  document.addEventListener("turbo:load", () => {
    // obtain plugin
    var cc = initCookieConsent();
    let locale = "fr";
    if (window.user_locale) {
      locale = window.user_locale;
    }

    // Warning global scope here.
    window.cc = cc;

    // run plugin with your configuration
    cc.run({
      // TODO: Update default lang with user lang.

      current_lang: locale,
      autoclear_cookies: true, // default: false
      page_scripts: true, // default: false

      // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
      // delay: 0,                               // default: 0
      // auto_language: null                     // default: null; could also be 'browser' or 'document'
      // autorun: true,                          // default: true
      force_consent: true, // default: false
      // hide_from_bots: false,                  // default: false
      // remove_cookie_tables: false             // default: false
      // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
      // cookie_expiration: 182,                 // default: 182 (days)
      // cookie_necessary_only_expiration: 182   // default: disabled
      // cookie_domain: location.hostname,       // default: current domain
      // cookie_path: '/',                       // default: root
      // cookie_same_site: 'Lax',                // default: 'Lax'
      // use_rfc_cookie: false,                  // default: false
      // revision: 0,                            // default: 0

      onFirstAction: function (user_preferences, cookie) {
        // callback triggered only once
      },

      onAccept: function (cookie) {
        // console.log("accept cookie ... ");
        // console.log(cookie);
        // ...
      },

      onChange: function (cookie, changed_preferences) {
        // console.log("Change cookie ... ");
        // console.log(cookie, changed_preferences)
        // ...
      },

      languages: {
        fr: {
          consent_modal: {
            title: "Cookies et conditions générales de ventes!",
            description: `En vous connectant, vous acceptez nos conditions générales, ainsi que le stockage de cookies
            qui permettent le fonctionnement essentiel du site. Nous utilisons aussi d'autres cookies a des fins de
            suivi de nos utilisateurs pour comprendre l'utilisation de notre plateforme.
            <div>
            <a target="_blank" href="/legal/cgu.pdf" class="cc-link"> Conditions générales </a>
            </div>

            <button type="button" data-cc="c-settings" class="cc-link">Je veux choisir</button>`,
            primary_btn: {
              text: "Accepter les CGV et tous les cookies",
              role: "accept_all", // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: "Accepter les CGV et rejeter les cookies optionnels",
              role: "accept_necessary", // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: "Préferences des cookies",
            save_settings_btn: "Sauvegarder les paramètres",
            accept_all_btn: "Accepter tout",
            reject_all_btn: "Rejeter tout",
            close_btn_label: "Close",
            cookie_table_headers: [
              { col1: "Nom" },
              { col2: "Domaine" },
              { col3: "Expiration" },
              { col4: "Description" },
            ],
            blocks: [
              {
                title: "Utilisation des cookies 📢",
                description: `
                     Nous utilisons les cookies pour permettre le fonctionnement basique du site et pour permettre un expérience améliorée de l’application.
                     Pour chaque catégorie, vous pouvez choisir d’y participer ou non quand vous le souhaitez.
                     Pour plus de détails sur les cookies et vos droits et devoirs sur Peac²h vous pouvez vous rendre sur la page <a href="/pages/license" class="cc-link">licence</a>.`,
              },
              {
                title: "Cookies strictement nécessaires",
                description:
                  "Ces cookies sont essentiels au fonctionnement du site. Sans ceux-ci le site ne peut pas fonctionner correctement.",
                toggle: {
                  value: "necessary",
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: "Cookies de performance et suivi d'utilisation",
                description: `
                    Ces cookies permettent le suivi de navigation par utilisateur. Ils nous permettent de connaître et mieux comprendre
                    l’utilisation de nos outils. Ils ne sont pas suivi pour du suivi publicitaire.
                    <a target="_blank" href="https://developers.google.com/analytics/devguides/collection/gtagjs/cookie-usage" class="cc-link">
                    `,
                toggle: {
                  value: "analytics", // your cookie category
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  // list of all expected cookies
                  {
                    col1: "^_ga", // match all cookies starting with "_ga"
                    col2: "google.com",
                    col3: "2 years",
                    col4: `
                            Identification et suivi par Google analytics. Suivi de session utilisateur.
                            `,
                    is_regex: true,
                  },
                  {
                    col1: "_gid",
                    col2: "google.com",
                    col3: "1 day",
                    col4: `
                            Identification par Google analytics.
                            `,
                  },
                ],
                // }, {
                //     title: 'Advertisement and Targeting cookies',
                //     description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                //     toggle: {
                //         value: 'targeting',
                //         enabled: false,
                //         readonly: false
                //     }
              },
              {
                title: "Plus dinformations",
                description:
                  'Pour tout question sur notre politique de cookies et les choix qui vous sont proposés, merci de <a class="cc-link" href="/pages/contact">nous contacter</a>.',
              },
            ],
          },
        },
        en: {
          consent_modal: {
            title: "Cookies and Terms of Sale!",
            description: `By logging in, you agree to our terms of sale, as well as the storage of cookies
              which are essential for the functioning of the site. We also use other cookies for tracking our
              users to understand the use of our platform.
              <div>
              <a target="_blank" href="/legal/cgu.pdf" class="cc-link"> Terms of Sale </a>
              </div>

              <button type="button" data-cc="c-settings" class="cc-link">I want to choose</button>`,
            primary_btn: {
              text: "Accept Terms of Sale and all cookies",
              role: "accept_all", // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: "Accept Terms of Sale and reject optional cookies",
              role: "accept_necessary", // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: "Cookie Preferences",
            save_settings_btn: "Save Settings",
            accept_all_btn: "Accept All",
            reject_all_btn: "Reject All",
            close_btn_label: "Close",
            cookie_table_headers: [
              { col1: "Name" },
              { col2: "Domain" },
              { col3: "Expiration" },
              { col4: "Description" },
            ],
            blocks: [
              {
                title: "Using Cookies 📢",
                description: `
                   We use cookies to enable the basic functioning of the site and to provide an enhanced application experience.
                   For each category, you can choose to participate or not when you want.
                   For more details on cookies and your rights and obligations on Peac²h you can go to the <a href="/pages/license" class="cc-link">license</a> page.`,
              },
              {
                title: "Strictly Necessary Cookies",
                description:
                  "These cookies are essential for the functioning of the site. Without these, the site cannot function properly.",
                toggle: {
                  value: "necessary",
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },

              {
                title: "Performance and Usage Tracking Cookies",
                description: `
                These cookies allow for user navigation tracking. They allow us to know and better understand the use of our tools. They are not used for advertising tracking.
                <a target="_blank" href="https://developers.google.com/analytics/devguides/collection/gtagjs/cookie-usage" class="cc-link">
                `,
                toggle: {
                  value: "analytics", // your cookie category
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  // list of all expected cookies
                  {
                    col1: "^_ga", // match all cookies starting with "_ga"
                    col2: "google.com",
                    col3: "2 years",
                    col4: `Tracking and monitoring by Google analytics. User session tracking.`,
                    is_regex: true,
                  },
                  {
                    col1: "_gid",
                    col2: "google.com",
                    col3: "1 day",
                    col4: `Identification by Google analytics.`,
                  },
                ],
                // }, {
                //     title: 'Advertisement and Targeting cookies',
                //     description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                //     toggle: {
                //         value: 'targeting',
                //         enabled: false,
                //         readonly: false
                //     }
              },
              {
                title: "More Information",
                description:
                  'For any questions about our cookie policy and the choices available to you, please <a class="cc-link" href="/pages/contact">contact us</a>.',
              },
            ],
          },
        },
        nl: {
          consent_modal: {
            title: "Cookies en Verkoopvoorwaarden!",
            description: `Door in te loggen, gaat u akkoord met onze verkoopvoorwaarden, evenals de opslag van cookies
              die essentieel zijn voor de werking van de site. We gebruiken ook andere cookies om het gebruik van ons platform
              door onze gebruikers te volgen en te begrijpen.
              <div>
              <a target="_blank" href="/legal/cgu.pdf" class="cc-link"> Verkoopvoorwaarden </a>
              </div>

              <button type="button" data-cc="c-settings" class="cc-link">Ik wil kiezen</button>`,
            primary_btn: {
              text: "Accepteer verkoopvoorwaarden en alle cookies",
              role: "accept_all", // 'accept_selected' of 'accept_all'
            },
            secondary_btn: {
              text: "Accepteer verkoopvoorwaarden en weiger optionele cookies",
              role: "accept_necessary", // 'settings' of 'accept_necessary'
            },
          },
          settings_modal: {
            title: "Cookievoorkeuren",
            save_settings_btn: "Instellingen opslaan",
            accept_all_btn: "Alles accepteren",
            reject_all_btn: "Alles weigeren",
            close_btn_label: "Sluiten",
            cookie_table_headers: [
              { col1: "Naam" },
              { col2: "Domein" },
              { col3: "Verloop" },
              { col4: "Beschrijving" },
            ],
            blocks: [
              {
                title: "Gebruik van Cookies 📢",
                description: `
                   We gebruiken cookies om de basisfunctionaliteit van de site mogelijk te maken en een verbeterde applicatie-ervaring te bieden.
                   Voor elke categorie kunt u kiezen om wel of niet deel te nemen wanneer u wilt.
                   Voor meer informatie over cookies en uw rechten en plichten op Peac²h kunt u naar de <a href="/pages/license" class="cc-link">licentie</a> pagina gaan.`,
              },
              {
                title: "Strikt noodzakelijke cookies",
                description:
                  "Deze cookies zijn essentieel voor de werking van de site. Zonder deze kan de site niet goed functioneren.",
                toggle: {
                  value: "noodzakelijk",
                  enabled: true,
                  readonly: true, // cookiecategorieën met readonly=true worden allemaal behandeld als "noodzakelijke cookies"
                },
              },

              {
                title: "Prestatie- en gebruiksopvolgingscookies",
                description: `
                Deze cookies maken het mogelijk om het navigatiespoor van gebruikers te volgen. Ze stellen ons in staat om het gebruik van onze tools te kennen en beter te begrijpen. Ze worden niet gebruikt voor het volgen van advertenties.
                <a target="_blank" href="https://developers.google.com/analytics/devguides/collection/gtagjs/cookie-usage" class="cc-link">
                `,
                toggle: {
                  value: "analyse", // uw cookiecategorie
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  // lijst van alle verwachte cookies
                  {
                    col1: "^_ga", // alle cookies die beginnen met "_ga"
                    col2: "google.com",
                    col3: "2 jaar",
                    col4: `Tracking en monitoring door Google Analytics. Gebruikerssessie volgen.`,
                    is_regex: true,
                  },
                  {
                    col1: "_gid",
                    col2: "google.com",
                    col3: "1 dag",
                    col4: `Identificatie door Google Analytics.`,
                  },
                ],
                // }, {
                //     title: 'Advertentie- en targetingcookies',
                //     description: 'Deze cookies verzamelen informatie over hoe u de website gebruikt, welke pagina\'s u hebt bezocht en op welke links u hebt geklikt. Alle gegevens zijn geanonimiseerd en kunnen niet worden gebruikt om u te identificeren',
                //     toggle: {
                //         value: 'targeting',
                //         enabled: false,
                //         readonly: false
                //     }
              },
              {
                title: "Meer informatie",
                description:
                  'Voor vragen over ons cookiebeleid en de beschikbare keuzes kunt u <a class="cc-link" href="/pages/contact">contact met ons opnemen</a>.',
              },
            ],
          },
        },
        pt: {
        consent_modal: {
          title: "Cookies e Termos de Venda!",
          description: `Ao fazer login, você concorda com nossos termos de venda, bem como com o armazenamento de cookies
            que são essenciais para o funcionamento do site. Também usamos outros cookies para rastrear nossos
            usuários e entender o uso de nossa plataforma.
            <div>
            <a target="_blank" href="/legal/cgu.pdf" class="cc-link"> Termos de Venda </a>
            </div>

            <button type="button" data-cc="c-settings" class="cc-link">Quero escolher</button>`,
          primary_btn: {
            text: "Aceitar Termos de Venda e todos os cookies",
            role: "accept_all", // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: "Aceitar Termos de Venda e rejeitar cookies opcionais",
            role: "accept_necessary", // 'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: "Preferências de Cookies",
          save_settings_btn: "Salvar Configurações",
          accept_all_btn: "Aceitar Todos",
          reject_all_btn: "Rejeitar Todos",
          close_btn_label: "Fechar",
          cookie_table_headers: [
            { col1: "Nome" },
            { col2: "Domínio" },
            { col3: "Expiração" },
            { col4: "Descrição" },
          ],
          blocks: [
            {
              title: "Usando Cookies 📢",
              description: `
                 Usamos cookies para permitir o funcionamento básico do site e fornecer uma experiência de aplicativo aprimorada.
                 Para cada categoria, você pode escolher participar ou não quando quiser.
                 Para mais detalhes sobre cookies e seus direitos e obrigações no Peac²h, você pode ir para a página de <a href="/pages/license" class="cc-link">licença</a>.`,
            },
            {
              title: "Cookies Estritamente Necessários",
              description:
                "Esses cookies são essenciais para o funcionamento do site. Sem eles, o site não pode funcionar corretamente.",
              toggle: {
                value: "necessary",
                enabled: true,
                readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
              },
            },

            {
              title: "Cookies de Desempenho e Rastreamento de Uso",
              description: `
              Esses cookies permitem o rastreamento da navegação do usuário. Eles nos permitem conhecer e entender melhor o uso de nossas ferramentas. Eles não são usados para rastreamento de publicidade.
              <a target="_blank" href="https://developers.google.com/analytics/devguides/collection/gtagjs/cookie-usage" class="cc-link">
              `,
              toggle: {
                value: "analytics", // your cookie category
                enabled: false,
                readonly: false,
              },
              cookie_table: [
                // list of all expected cookies
                {
                  col1: "^_ga", // match all cookies starting with "_ga"
                  col2: "google.com",
                  col3: "2 anos",
                  col4: `Rastreamento e monitoramento pelo Google Analytics. Rastreamento de sessão do usuário.`,
                  is_regex: true,
                },
                {
                  col1: "_gid",
                  col2: "google.com",
                  col3: "1 dia",
                  col4: `Identificação pelo Google Analytics.`,
                },
              ],
              // }, {
              //     title: 'Advertisement and Targeting cookies',
              //     description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
              //     toggle: {
              //         value: 'targeting',
              //         enabled: false,
              //         readonly: false
              //     }
            },
            {
              title: "Mais Informações",
              description:
                'Para qualquer dúvida sobre nossa política de cookies e as opções disponíveis para você, por favor, <a class="cc-link" href="/pages/contact">entre em contato conosco</a>.',
            },
          ],
        },
        },
      },
    });
  });
}
