import { Controller } from "@hotwired/stimulus";

// Addition ?
// https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/

export default class extends Controller {
  static values = {
    activation: Boolean,
    loading: String,
    baseurl: String,
    display: String,
    targetUrl: String
  };

  connect() {
    document.addEventListener('keydown', this.handleEscapeKey.bind(this));

    if(this.activationValue) { 
      this.activation(); 
    }
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleEscapeKey.bind(this));
  }

  handleEscapeKey(event) {
    if(this.activated){ 
      if (event.key === 'Escape') {
        this.deactivation();
      }
    }
  }

  activation(event = null) {
    this.activated = true; 

    if(event){
      // event.stopPropagation();
      // Display loading while waiting for data to load.
      this.loading();
    }

    if(this.targetUrlValue != ""){
      window.history.pushState({}, {}, this.targetUrlValue);
    }

    // TODO: pass this as controller class argument
    document.body.classList.toggle("modal-open");

    const modal = document.getElementById("modal");

    // TODO: pass this as controller class argument
    modal.classList.toggle("opened");
  }

  loading(){
    const myElement = document.getElementsByClassName("modal-body")[0];

    let loadingText = "Loading";
    if (this.hasLoadingValue) {
      loadingText = this.loadingValue;
    }
    myElement.innerHTML = `<div class="lds-dual-ring"> ${loadingText} </div>`;
  }

  deactivation() {
    this.activated = false;

    // TODO: navigation
    document.body.classList.toggle("modal-open");
    const modal = document.getElementById("modal");
    modal.classList.toggle("opened");

    if(this.hasBaseurlValue){
      window.history.pushState({}, {}, this.baseurlValue);
    }
   
  }
}
