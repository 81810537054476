import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    options: Array,
    configurations: Array,
  };

  static targets = ["configuration", "dropdown", "name"];

  connect() {
    for (let dd of this.dropdownTargets) {
      dd.addEventListener("click", () => {
        let index = this.optionsValue.indexOf(dd.value)
        this.configurationTarget.value = this.configurationsValue[index];
        this.nameTarget.value = dd.value;
      });
    }
  }
}
