import { Controller }from "@hotwired/stimulus"
import toastr from 'toastr';

// See the demo for examples: https://codeseven.github.io/toastr/demo.html

export default class extends Controller {

  static values = { automatic: Boolean, 
                    type: String,
                    text: String, 
                  }


  connect(){

    // Default options. 
    toastr.options = {
      "closeButton": true,
      "debug": false,
      "newestOnTop": true,
      "progressBar": true,
      "positionClass": "toast-bottom-right",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }

    if(!this.hasTypeValue){
      this.typeValue = "info";
    }

    if(this.automaticValue && this.textValue !== ""){ 
      toastr[this.typeValue](this.textValue);
      this.textValue = "";
    }
  }

}

