import { Controller }from "@hotwired/stimulus"
// Trigger a refresh of Best in place.

export default class extends Controller {
  
  static targets = [ "selectable" ]
  static classes = [ "selected", "parent" ]
  static values = {
    scrollDestination: String
  }

  connect() {
  }

  select(event){
    this.unSelectAll();
    const parent = event.target.closest("." + this.parentClass);
    if(parent){
      parent.classList.add(this.selectedClass);
    }

    if(this.hasScrollDestinationValue){
      setTimeout(() => {  
        let element = document.getElementById(this.scrollDestinationValue)
        if(element){
          element.scrollIntoView(); 
        }
      }, 800);
      // Here 800 is a hopeful large value for server response 
    }
  }

  unSelectAll(){
    this.selectableTargets.forEach( (selectable) => {
      selectable.classList.remove(this.selectedClass);
    });
  }
}
