////////////////////
// Rails 6 Basics
////////////////////

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import $ from 'jquery';
window.$ = $;

////////////////////
// Cookie consent
////////////////////

import "../app/cookie-manager";

////////////////////
// User Tracking
////////////////////

import "../app/analytics";

////////////////////
// Error management
////////////////////

// console.log("ENV:", process.env.RAILS_ENV);


////////////////////
// Major frameworks
////////////////////

// StimulusJS Import
import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'
window.Stimulus = Application.start()

// const controllers = import.meta.glob('../**/*_controller.js', {eager: true})
// registerControllers(window.Stimulus, controllers)

const mainControllers = import.meta.glob(['../controllers/*_controller.js'], {eager: true})
registerControllers(window.Stimulus, mainControllers)

import ViewerController from "../controllers/survey/survey_viewer_controller";
import CreatorController from "../controllers/survey/creator_controller";

window.Stimulus.register("creator", CreatorController);
window.Stimulus.register("survey-viewer", ViewerController);


//////////////////
// Turbo
//////////////////

import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = true;
window.Turbo = Turbo

////////////////////
// Minor frameworks
////////////////////

// Best in place
// require("../vendor/best_in_place");

// Trix + ActionText are global to the app (not with Stimulus)
import "trix"
import "@rails/actiontext"

// const Trix = require("trix");
// require("@rails/actiontext");

// Simple table sort from https://github.com/tofsjonas/sortable
import "../vendor/sortable.js";

////////////////////////////
/// Some initialisations
////////////////////////////

document.addEventListener("turbo:load", () => {
  // Auto-scroll Y to previous location
  // DISABLED
  // if (sessionStorage.getItem("scrollY-" + window.location.pathname)){
  //   window.scrollTo(0, sessionStorage.getItem("scrollY-" + window.location.pathname));
  // }

  // Store pill state in history - WORK in progress
  document.querySelectorAll('a[data-toggle="pill"]').forEach((element) => {
    element.addEventListener("shown.bs.tab", function (e) {
      let path = window.location.pathname;
      let target = e.target.id;
      // Remove the "-tab" at the end
      target = target.substring(0, target.length - 4);

      //    https://github.com/turbolinks/turbolinks/issues/219
      window.history.replaceState(history.state, "", "?selected=" + target);
    });
  });
});

// DISABLED
// $(document).on("turbo:before-visit", () =>{
//   // Auto-scroll Y to previous location
//   sessionStorage.setItem("scrollY-" + window.location.pathname, window.scrollY);
// });

////////////////////////////////////////
///// ActiveStorage configuration /////
//////////////////////////////////////

addEventListener("direct-upload:initialize", (event) => {
  const { target, detail } = event;
  const { id, file } = detail;
  target.insertAdjacentHTML(
    "beforebegin",
    `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `
  );
  target.previousElementSibling.querySelector(
    `.direct-upload__filename`
  ).textContent = file.name;
});

addEventListener("direct-upload:start", (event) => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.remove("direct-upload--pending");
});

addEventListener("direct-upload:progress", (event) => {
  const { id, progress } = event.detail;
  const progressElement = document.getElementById(
    `direct-upload-progress-${id}`
  );
  progressElement.style.width = `${progress}%`;
});

addEventListener("direct-upload:error", (event) => {
  event.preventDefault();
  const { id, error } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add("direct-upload--error");
  element.setAttribute("title", error);
});

addEventListener("direct-upload:end", (event) => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add("direct-upload--complete");
});

//////////////////////////////////////////////
///// Trix configuration for attachments /////
//////////////////////////////////////////////

// Trix attachment disabled for now.

document.addEventListener("trix-file-accept", function (event) {
  event.preventDefault();
});



import * as Sentry from "@sentry/browser";
let popUpDisabled = process.env.SENTRY_POPUP_DISABLED === "true"; 

let rate = 0.2;
if (
  process.env.RAILS_ENV === "development" ||
  process.env.RAILS_ENV === "test" ||
  process.env.RAILS_URL === "app"
) {
  rate = 0.0;
}

if(rate > 0){ 
  Sentry.init({
    dsn: "https://055615d6b39b4cb297774d7d9f1dd9d3@o608943.ingest.sentry.io/5745772",

    // Browser tracking gets blocked due to limitations of the free plan.
    // It is disabled for now.
    // integrations: [new Integrations.BrowserTracing()],
    release: "peac²h@" + process.env.APP_VERSION,

    beforeSend(event, hint) {
      // TODO: Automatic profile setting etc... ?
      // https://docs.sentry.io/platforms/javascript/enriching-events/user-feedback/

      if (rate > 0) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception && !popUpDisabled) {
          // console.log("Exception ", event);
          Sentry.showReportDialog({ eventId: event.event_id });
        }else {
              // console.log("Exception ", event);
        }
        return event;
      }
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production

    tracesSampleRate: rate,
  });

  window.Sentry = Sentry;
}
