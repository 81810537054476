import { Controller } from "@hotwired/stimulus";
// Preview images when they are
// selected to upload.

export default class extends Controller {
  static targets = ["image", "comment", "error"];
  
  static values = {
    maxSize: Number, 
    sizeCheckOnly: Boolean,
  } 

  // TODO: Find a better translation technique.
  // I18n
  error_message(file_name) {

    let size = "1";
    if(this.hasMaxSizeValue && this.maxSizeValue > 0){
      size = this.maxSizeValue;
    }

    let message = `Le fichier ${file_name} a une taille trop importante, maximum ${size}Mo.`;
    if (window.user_locale == `en`) {
      message = `File size of ${file_name} is too big, it must be below ${size}MB.`;
    }
    return message;
  }

  readURL(input) {
    let max_size_limit = 1048576; // 1 MB in byte.

    if (this.hasMaxSizeValue && this.maxSizeValue > 0) {
      max_size_limit = this.maxSizeValue * 1048576;
    } else {
      max_size_limit = 0; // 0 is no limit
    }

    if (this.hasSizeCheckOnlyValue) {
      return this.checkSizes(input, max_size_limit);
    }
  
    // TODO: loop here
    if (input.files?.[0]?.size && input.files[0]) {
      this.loadImagePreview(input, max_size_limit); 
    }
  }

  checkSizes(input, max_size_limit){
    let errorMessages = [];

    for (let i = 0; i < input.files.length; i++) {
      let size = input.files[i].size;
      

      if (max_size_limit && size > max_size_limit) {
        // Add the error message to the errorMessages array
        errorMessages.push(this.error_message(input.files[i].name));
      }
    }

    // Display the error messages in the target element
    this.errorTarget.innerHTML = errorMessages.join("<br>");

    console.log(errorMessages, this, errorMessages.length);
    // Reset the file input if there are any errors
    if (errorMessages.length > 0) {
      // input.value = "";
    }
  }


  loadImagePreview(input, max_size_limit){

    if (max_size_limit && input.files[0].size > max_size_limit) {
      // Reset the file input
      console.log("Input: ", input);
      input.value = "";
      // $('#id_of_input_file_field').val('');
      alert(this.error_message());

    } else {

      if(this.hasSizeCheckOnlyValue){
        return;
      }

      var reader = new FileReader();
      reader.onload = (e) => {
        if (this.hasImageTarget) {
          this.imageTarget.setAttribute("src", e.target.result);
        }
        if (this.hasCommentTarget) {
          this.commentTarget.classList.remove("d-none");
        }
      };
      reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
  }


  fileSelected(event) {
    this.readURL(event.target);
  }
}
