import { Controller }from "@hotwired/stimulus"
// Trigger a refresh of Best in place.
import $ from 'jquery';

export default class extends Controller {

  // Best in place initialisation
  connect() {

    this.loadBIP().then( () =>  { 
      $(".best_in_place").best_in_place();
    });
  }

  async loadBIP(){

    await import( 
      /* webpackChunkName: "best_in_place" */ "../vendor/best_in_place"
    );
  }
}
