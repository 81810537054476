import { Controller }from "@hotwired/stimulus"

export default class extends Controller {
  // TODO: element is non-discriptive. Refactor to "ToReveal" or "HiddenAtLoad" 
  static targets = [ "message"]

  display(){
    this.messageTargets.forEach( (element) => {
      element.classList.remove("hidden");
    });
  }

  reset(){
    this.messageTargets.forEach( (element) => {
      element.classList.add("hidden");
    });
  }

}
