import { Controller }from "@hotwired/stimulus"

// import tippy from 'tippy.js';

export default class extends Controller {

  static values = {
    trigger: String, 
    allowHtml: Boolean, 
    deactivation: Boolean,
    delay: Number,
    theme: String,
    placement: String,
  }

  connect() {
    super.connect()
    
    // Compatiblity with previous tooltips with JQuery
    if(this.element.hasAttribute("title")){
      var title = this.element.getAttribute("title");
      this.element.setAttribute("data-tippy-content", title);
    }  

    let options = {}
    // options.placement = "left";
    if(this.triggerValue) {
      options.trigger = this.triggerValue; 
    }
    if(this.allowHtmlValue){
      options.allowHTML = this.allowHtmlValue;
      options.interactive = true;
    }

    if(this.hasDelayValue){
      options.delay = this.delayValue;
    }
    if (this.themeValue) {
      options.theme = this.themeValue;
    }

    if (this.hasPlacementValue) {
      options.placement = this.placementValue;
    }

    // options.allowHTML = true;

    if(this.hasDeactivationValue){
      return; 
    }

    this.loadTippy(options);
  }

  async loadTippy(options){

    const { default: tippy } = await import( 
      /* webpackChunkName: "tippy" */ 'tippy.js'
    );
    this.tips = tippy(this.element, options);
  }

  disconnect(){
    if(this.tips){
      this.tips.hide(); 
    }
    
    //  $(() => $(this.element).tooltip('hide') )
  }

  show(){
   // console.log("show");
    // Mimic Toolips included in Boostrap
    // this.tips = tippy(this.element);
  }

  hide(){
   //  console.log("hide");
  }


}
