import { Controller } from "@hotwired/stimulus"
import toastr from "toastr";

// import Papa from 'papaparse';

// Enable the copy of an input to clipboard

export default class extends Controller {
  static targets = ["button", "source"]
  static values = {
    copied: String, 
    textInfoValue: String,
  }

  connect() {
    if (document.queryCommandSupported("copy")) {
      if (this.hasButtonTarget) {
        this.buttonTarget.classList.remove("disabled");
      }
    }

    toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: true,
      progressBar: true,
      positionClass: "toast-bottom-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "2000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
  }

  copy() {
    this.sourceTarget.select()
    document.execCommand("copy")

    console.log(this);
    if(this.hasTextInfoValue){
      toastr["info"](this.textInfoValue);
    }
    
  }

  formatWithoutLB() {
    // Remove line breaks from the input text
    const textWithoutLineBreaks = this.sourceTarget.value.replace(/(\r\n|\n|\r)/gm, "");
    this.sourceTarget.value = textWithoutLineBreaks;
  }


  copyWithoutLB() {
    // Select the input text
    this.sourceTarget.select();

    // Remove line breaks from the input text
    const textWithoutLineBreaks = this.sourceTarget.value.replace(/(\r\n|\n|\r)/gm, "");

    // Copy the modified text to the clipboard
    navigator.clipboard.writeText(textWithoutLineBreaks);

    toastr["info"](this.copiedValue);
  }

  formatWithoutLB() {
    // Remove line breaks from the input text
    const textWithoutLineBreaks = this.sourceTarget.value.replace(/(\r\n|\n|\r)/gm, "");
    this.sourceTarget.value = textWithoutLineBreaks;
  }


  copyWithoutLB() {
    // Select the input text
    this.sourceTarget.select();

    // Remove line breaks from the input text
    const textWithoutLineBreaks = this.sourceTarget.value.replace(/(\r\n|\n|\r)/gm, "");

    // Copy the modified text to the clipboard
    navigator.clipboard.writeText(textWithoutLineBreaks);

    toastr["info"](this.copiedValue);
  }

  saveData() {
    let name = "data.txt"

    if (this.data.has("name")) {
      name = this.data.get("name");
    }

    this.openSaveFileDialog(name);
  }

  openSaveFileDialog(filename, mimetype) {
    let data = this.sourceTarget.innerHTML;

    /* var config = {
     *   quotes: false, //or array of booleans
     *   quoteChar: '"',
     *   escapeChar: '"',
     *   delimiter: ";",
     *   header: true,
     *   newline: "\r\n",
     *   skipEmptyLines: false, //or 'greedy',
     *   columns: null //or array of strings
     * }
     * let parsed = Papa.parse(data);
     * let unparsed = Papa.unparse(parsed, config);
     * data = unparsed;
     */
    if (!data) return;

    var blob = data.constructor !== Blob
      ? new Blob([data], { type: mimetype || 'application/octet-stream' })
      : data;

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
      return;
    }

    var lnk = document.createElement('a'),
      url = window.URL,
      objectURL;

    if (mimetype) {
      lnk.type = mimetype;
    }

    lnk.download = filename || 'untitled';
    lnk.href = objectURL = url.createObjectURL(blob);
    lnk.dispatchEvent(new MouseEvent('click'));
    setTimeout(url.revokeObjectURL.bind(url, objectURL));
  }

}
