import { Controller } from "@hotwired/stimulus";

/**
 * Item selector controller enables the selection
 * of active items. (see active_item_controller.js)
 *
 */
export default class extends Controller {
  static targets = ["button"];
  // static classes = ["disabled"];
  static values = { selected: Number, id: String };

  // outlets 
  static outlets = [ "active-item" ]
  static classes = [ "toggled" ] 

  connect() {
    // Try to get the value from localstorage
    let storedValue = localStorage.getItem(this.name());

    if (storedValue != null) {
      this.selectedValue = storedValue;
    } 

    this.selectButton();
  }

  selectButton() {
    this.buttonTargets.forEach( (button) => {

      let buttonId = button.dataset.itemSelectorIdParam;

      let buttonClass = "button-toggled"
      
      if(this.hasToggledClass){
        buttonClass = this.toggledClass;
      }

      if(buttonId == this.selectedValue){
        button.classList.add(buttonClass)
      } else {
        button.classList.remove(buttonClass)
      }
    }); 
  }
  
  activeItemOutletConnected(outlet, element) {
    outlet.refresh(this.selectedValue);
  }

  name() {
    let id = this.idValue;
    return "item-selector-" + id;
  }

  activate(event) {
    // Not a real navigation
    event.preventDefault();

    this.selectedValue = event.params.id; 

    if(this.hasActiveItemOutlet){
      this.activeItemOutlets.forEach(item => item.refresh(this.selectedValue));
    }
    
    this.selectButton();
    // save this to a cookie
    localStorage.setItem(this.name(), this.selectedValue);
  }

  activateSubject(event) {
    // Not a real navigation
    event.preventDefault();

    this.selectedValue = event.params.id; 
    this.selectedSubject = event.params.subject; 

    if(this.hasActiveItemOutlet){
      this.activeItemOutlets.forEach(item => item.refreshSubject(this.selectedValue, this.selectedSubject));
    }
    
  }

}
