import { Controller }from "@hotwired/stimulus"

// TODO: check for deletion
export default class extends Controller {
  // static targets = [ "output", "hidden" ]

  connect() {
    console.log("In studies controller");
  }

  /* selectGroup() {
   *   this.stimulate("StudiesReflex#select_group", event.target)
   * }
   */
}

/* edit(event) {
 *   this.stimulate("Folder#edit", event.target)
 * } */
