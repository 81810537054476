import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = ["vega"];

  // Note: outlets must have the same name as the controller
  static outlets = ["vega-simple-bar"];
  
  static values = {
    chartData: String
  };

  vegaSimpleBarOutletConnected(outlet) {
    outlet.setParameters();
    outlet.displayChart(this.chartDataValue);
  }
}
