window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

// Only in production mode 
if(! (process.env.RAILS_ENV === "development" || process.env.RAILS_ENV === "test")) {
// if( true ) {

  // TODO: GTAG currently not passing... 
  if (process.env.USE_GTAG == "true") {
    // Done at load... 
    document.addEventListener("turbo:load", (event) => {

      // Follow the user's preferences...
      if(window.cc && cc.allowedCategory("analytics")) {

        if (Object.keys(event.detail.timing).length === 0) {
          gtag('js', new Date());
          gtag('config', 'G-YBWBRWLECQ');
        }
      
      }
      // Send custom events?
      // gtag('event', 'toto');
    });
  }
}

export default gtag;

// HTTP command -> possible way to send custom events.
// https://region1.google-analytics.com/g/collect?v=2&

// tid=G-YBWBRWLECQ&
// gtm=2oe6d0&
// _p=1247089269&
// _dbg=1&
// _z=ccd.tdB&
// cid=411470535.1655282346&
// _s=2&
// uid=da5c7430-c158-4445-b269-8ff5ab1932c3&
// sid=1655282346&
// sct=1&
// seg=1&
// en=toto&
// up.user_type=manager

