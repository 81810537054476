// import Sortable, { MultiDrag } from 'sortablejs';
import { FetchRequest } from '@rails/request.js'
import { Controller }from "@hotwired/stimulus"

// TODO: Stimulus JS cleaning.
// Drag'n drop with multiple targets
export default class extends Controller {
  static targets = [ "creator" ]

  connect() {
    super.connect();
    this.initSortable(); 
  }
  
  async initSortable() {
    const { default: Sortable, MultiDrag } = await import('sortablejs');

    if(window.multiDrag === undefined){
      Sortable.mount(new MultiDrag());
      window.multiDrag = true;
    }

    this.selectedItems = [];

    let groupName = this.data.get("group");
    var sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
      group: groupName,
      animation: 100,
      handle: ".handle",

      // multiDrag: true, // Enable the plugin
      selectedClass: "sortable-selected", // Class name for selected item
      // multiDragKey: null, // 'CTRL'// Key that must be down for items to be selected
    
      // Called when an item is selected
      onSelect: (evt) => { 
        //evt.item // The selected item
        // Store the element
        this.selectedItems.push(evt.item);
      },
    
      // Called when an item is deselected
      onDeselect: (evt) => {
        // evt.item // The deselected item
        
        // Remove the element from the array
        this.selectedItems = this.selectedItems.filter(item => item !== evt.item);
      }
    });

  }    


  // Triggered from the starting element.
  end(event){

    let groupId = event.to.dataset.assignerGroupId;
    let studyId = this.data.get("studyId");
  
     // empty form initialization for Ajax submission (HTML/JS standard)
     let data = {};
  
     let subjectId = event.item.dataset.itemId;  

     data["group_id"] = groupId;
     data["position"] = event.newIndex;
    // Do this for all the selected elements... 

    let subjectIDs = [];
  //  data["subject_id"] = subjectId);

    //console.log(this.selectedItems);
    
    if(this.selectedItems.length == 0){
   //   console.log("Nothing selected !");
      subjectIDs.push(subjectId);
    } else {

     // console.log("Selected amount: " + this.selectedItems.length);
      this.selectedItems.forEach(item => {
        subjectIDs.push(item.dataset.itemId);
      });
    }

    data["subject_ids"] = subjectIDs;

    const request = new FetchRequest('post', 
        "/subjects/" + subjectId + "/update_group", {
      body: JSON.stringify(data)
    })
    const response = request.perform();
    console.log(response);
    // if (response.ok) {
    //   const body = await response.text
    // }

    // Transition out of Rails UJS
    // Rails.ajax({
    //   url: "/subjects/" + subjectId + "/update_group",
    //   type: 'POST',
    //   data: data,
    //   success: () => {
    //     // console.log("Success");
    //  }
    // });
 
  }
  

}
