import { Controller } from "@hotwired/stimulus";
import { Base64 } from "js-base64";
import { FetchRequest } from '@rails/request.js'
// import likert from "../../widgets/likert";
// import likertMatrix from "../../widgets/likertMatrix";
import toastr from "toastr";

// imported in CSS
// import 'survey-core/defaultV2.min.css';
import ReactDOM  from "react-dom/client";
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

import "survey-core/i18n/french";
import "survey-core/i18n/english";
import "survey-core/i18n/portuguese";
import "survey-core/i18n/dutch";

// import { registerLikert } from '../../widgets/QuestionLikertModel';
// import theme from "../survey-theme";
import "../../widgets/likert"
import "../../widgets/likertMatrix"
import "../../widgets/noUiWidget"
import "../../widgets/noUiWidget-Custom"
import "../../widgets/noUiWidget-TLX"

// registerLikert();

export default class extends Controller {
  static targets = ["survey", "buttons", "preview", "edit"];

  static values = {
    content: String,
    currentData: String,
    resultId: Number,
    review: Boolean,
    id: Number, 
    url: String,
    locale: String,
    networkError: String,
    updateForbidden: String
  };

  connect() {
    this.loadingText = this.surveyTarget.innerHTML;
    // Default toastr options.
    toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: true,
      progressBar: true,
      positionClass: "toast-bottom-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };

    this.loadSurvey().then(() => this.formatStyleMatrix());
  }

  disconnect() {
    this.surveyTarget.innerHTML = this.loadingText;
  }

  isFakeResult() {
    return this.resultIdValue === -1;
  }

  isSubjectUpload() { 
    return this.urlValue.startsWith("import");
  }

  uploadSubjectsSurvey() {
    return  {
      "pages": [
       {
        "name": "page1",
        "elements": [
         {
          "type": "html",
          "name": "question2",
          "html": "Example de fichier <a href=\"/sample_data/subject_list.csv\"> à télécharger. <a> \n"
         },
         {
          "type": "file",
          "name": "question1",
          "title": "Fichier CSV de liste de sujets.",
          "hideNumber": true,
          "isRequired": true,
          "allowImagesPreview": false,
          "acceptedTypes": "*.csv"
         }
        ]
       }
      ],
      "showPageTitles": false,
      "showCompletedPage": false,
      "completeText": "Envoyer"
     }
  }
  

  activateDisplayMode() {
    this.surveyModel.mode = "display";
    this.previewTarget.classList.add("inactive");
    this.editTarget.classList.remove("inactive");
  }
  activateEditMode() {
    this.surveyModel.mode = "edit";
    this.previewTarget.classList.remove("inactive");
    this.editTarget.classList.add("inactive");
  }


  // TODO: Update this, or remove it
  formatStyleMatrix() {
    // Get all matrix elements
    let items = document.querySelectorAll(".sv_q_matrix");
    items.forEach(
      (element) =>
        (element.closest(".sv_row").children[0].style.backgroundColor =
          "var(--experiment-background-color)")
    );
  }

  async loadSurvey() {
  
  
    //  survey.applyTheme(theme);

    try {
      if(this.isSubjectUpload()){
        this.surveyModel = new Model(this.uploadSubjectsSurvey());
      }else {
        let surveyContent = Base64.decode(this.contentValue);
        let json = JSON.parse(surveyContent);  
        this.surveyModel = new Model(json);
      }
      
      // Load the survey questions
      if(this.hasLocaleValue){
        this.surveyModel.locale = this.localeValue;
      }
      if (this.isFakeResult()) {
        this.buttonsTarget.classList.remove("hidden");
      }

      // Prepare the saved data
      if (this.hasCurrentDataValue) {
        let data = JSON.parse(Base64.decode(this.currentDataValue));
        this.surveyData = data;

        // console.log("Survey saved data: ", data);
        this.surveyModel.data = data;
        if (this.surveyData && this.surveyData.pageNo) {
          //console.log("Restoring page...")
          this.surveyModel.currentPageNo = this.surveyData.pageNo;
        }
      }

      if (this.reviewValue) {
        this.activateDisplayMode();
      } else {

        if(!this.isSubjectUpload()){ 
          this.autoUpdate(this.surveyModel.data, this.surveyModel.currentPageNo);

          // Hooks
          // Page change hook, update the page.
          this.surveyModel.onCurrentPageChanged.add(() => {
            this.autoUpdate.bind(this)(
              this.surveyModel.data,
              this.surveyModel.currentPageNo
            );
          });

          this.surveyModel.onCurrentPageChanged.add(() => {
            this.formatStyleMatrix();
          });

        }

        // Complete hook, finalize the survey.
        this.surveyModel.onComplete.add(() => {
          this.sendDataToServer.bind(this)(this.surveyModel, this);
        });

        // TODO: Re-enable files and implement this 
        // Upload goes to a default attachment ?
        // this.surveyModel.onUploadFile.add(async (_, options) => { 
        //   options.files.forEach(async (file) => {
        //     const formData = new FormData();
        //     formData.append("file", file);
        //     console.log("Posting file: ", file)
        //     const request = new FetchRequest('post', 
        //       "/survey_contents/" + this.idValue + "/attach", 
        //       {
        //         body: formData
        //       })
        //     const response = await request.perform();
        //     if (response.ok) {
        //       options.callback(
        //         "success",
        //         await response.text
        //         // A link to the uploaded file                
        //     );
        //     }else {
        //     alert(response);
        //     }
        //   });
        // }); 
          

      }

      console.log("Mounting... controller connected !")
      let root = ReactDOM.createRoot(this.surveyTarget);
      root.render(<Survey model={this.surveyModel}/>);

    } catch (e) {
      console.log("Loading failed ");
      console.log(e);
    }
  }

  savePdf(data, json, SurveyPDF) {
    console.log("save");
    const surveyPdf = new SurveyPDF.SurveyPDF(json, {});
    surveyPdf.data = data;
    surveyPdf.save();
  }

  async autoUpdate(surveyData, currentPage) {
    // Add current page number
    surveyData.pageNo = currentPage;

    var resultAsString = JSON.stringify(surveyData);
    let data = {};
    data["result"] = resultAsString;
    data["userAgent"] = window.navigator.userAgent;

    let url = "";
    let type = "PATCH";

    if (!this.isFakeResult()) {
      let resultId = this.resultIdValue;
      data["id"] = resultId;

      url = "/experience_results/" + resultId + "/partial_answer";

      const request = new FetchRequest('patch', url, {
        body: JSON.stringify(data)
      })
      const response = await request.perform();

      console.log(response); 

      if (response.ok) {
         console.log("auto save OK");
      } else {
          console.log(response)
          if(response.statusCode == 401){
            toastr["warning"](this.updateForbiddenValue);
      
          } else {
            toastr["error"](this.networkError);
          }
      }

    }
  }

  async sendDataToServer(survey) {
    if (this.isFakeResult()) {
      return;
    }

    let surveyData = survey.data;
    surveyData.pageNo = survey.currentPageNo + 1;
    var resultAsString = JSON.stringify(surveyData);

    let reqData = {};
    reqData["result"] = resultAsString;
    reqData["userAgent"] = window.navigator.userAgent;

    let url = "";
    let type = "PATCH";

    let resultId = this.resultIdValue;
    reqData["id"] = resultId;
    url = "/experience_results/" + resultId + "/answer";

    if(this.isSubjectUpload()){
      url = "/experimental_studies/" + this.idValue + "/import_subjects";
    }

    if (resultId === "-1") {
      console.log("No result target, nothing is sent to server");
      return;
    }
    console.log("URL SENDING", url);
    const request = new FetchRequest('patch', url, {
      body: JSON.stringify(reqData)
    })
    const response = await request.perform();
    if (response.ok) {
        console.log("Data submitted successfully");

        if(this.isSubjectUpload()){
          Turbo.visit("/experimental_studies/" + this.idValue + "/subject_groups")
        }

    } else {

 
      if(response.statusCode == 401){
        toastr["warning"](this.updateForbiddenValue);
      } else {
        toastr["error"](this.networkError);
      }

      if(response.statusCode == 400){
        toastr["warning"]("Error in the data submitted.");
        if(this.isSubjectUpload()){

          setTimeout(() => Turbo.visit("/experimental_studies/" + this.idValue + "/subject_groups"), 2000);
        }
      }
    }

  }
}