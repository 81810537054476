import { Controller }from "@hotwired/stimulus"

import gtag from '../app/analytics';

//window.dataLayer = window.dataLayer || [];
//function gtag(){dataLayer.push(arguments);}


export default class extends Controller {

  static values = {
    id: String, 
    rights: String,
    locale: String
  }

  connect(){
    gtag('config', 'G-YBWBRWLECQ', { 
       "user_id": this.idValue
    });

    gtag('set', 'user_properties', {
      user_type: this.rightsValue
    });

    window.user_locale = this.localeValue; 
    //window.user_id = this.idValue;
    //console.log("GTAG USER controller set...", window.user_id);
  }

  //track(event){

    // gtag('config', 'G-YBWBRWLECQ', { 
    //   "user_id": window.user_id, 
    //   'debug_mode': true
    // });
    
    // Called on the first navigation 
    // console.log("TRACK", window.user_id);
    // console.dir(event);
    // console.log(event, event.params, event.detail);

    //  gtag('set', { 
    //    'page_location': event.detail.url, 
    //    'page_path': event.currentTarget.location.pathName,
    //    'page_title': event.currentTarget.title 
    //  }); 
    // gtag('event', 'page_view');
  //}
}
