import { Controller } from "@hotwired/stimulus";

/**
 * Collapse targets should be hidden at page load.
 * Expand targets should be visible at page load.
 *
 * Usually expand is called first to show / something,
 * then collaspe is called to hide what was shown.
 *
 */
export default class extends Controller {
  static targets = ["collapse", "expand", "text"];
  static classes = ["collapsed", "height"];
  static values = { collapsed: Boolean, id: String };

  connect() {
    // If there is an ID

    let storedValue = localStorage.getItem("top-height-collapse-status");

    if (storedValue != null) {
      this.collapsedValue = storedValue;
    }

    if (this.collapsedValue) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  toggle() {
    if (this.collapsedValue) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  collapse() {
    this.collapsedValue = true;
    const root = document.querySelector(":root");
    root.style.setProperty("--top-height", "8em");
    localStorage.setItem("top-height-collapse-status", true);
  }

  expand() {
    const root = document.querySelector(":root");
    root.style.setProperty("--top-height", "13em");
    localStorage.setItem("top-height-collapse-status", false);
    this.collapsedValue = false;
  }
}
