import { Controller }from "@hotwired/stimulus"

// Select2 is now using TomSelect ! 
import TomSelect from "tom-select";


export default class extends Controller {

  connect () {
    let settings = {};
    this.tomSelect = new TomSelect(this.element,settings);
  }

  disconnect(){
    this.tomSelect.destroy();
  }

}
