import { Controller } from "@hotwired/stimulus";

/**
 * Collapse targets should be hidden at page load.
 * Expand targets should be visible at page load.
 *
 * Usually expand is called first to show / something,
 * then collaspe is called to hide what was shown.
 *
 */
export default class extends Controller {
  static targets = ["collapse", "expand", "text"];
  static classes = ["collapsed", "height"];
  static values = { collapsed: Boolean, id: String };

  connect() {
    // If there is an ID
    if (this.idValue) {
      let storedValue = localStorage.getItem(this.name());

      if (storedValue != null) {
        this.collapsedValue = storedValue;
      }
    }

    if (this.collapsedValue) {
      this.collapse();

      // Warning: a Rewrite of the toggle text and usage
      // might be required.
      this.toggleText();
    } else {
      this.expand();
    }
  }

  name() {
    let id = this.idValue;
    return "collapse-" + id;
  }

  toggle() {
    if (this.collapsedValue) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  collapse() {
    this.element.classList.add(this.collapsedClass);
    this.collapseInside();
    this.toggleText();
    this.collapsedValue = true;
  }

  expand() {
    this.element.classList.remove(this.collapsedClass);
    this.expandInside();
    this.toggleText();
    this.collapsedValue = false;
  }

  collapseInside() {
    this.collapseTargets.forEach((element) => {
      element.classList.add("hidden");
    });
    this.expandTargets.forEach((element) => {
      element.classList.remove("hidden");
    });

    if (this.idValue) {
      localStorage.setItem(this.name(), true);
    }
  }

  toggleText() {
    this.textTargets.forEach((element) => {
      element.classList.toggle(this.heightClass);
    });
  }

  expandInside() {
    this.collapseTargets.forEach((element) => {
      element.classList.remove("hidden");
    });
    this.expandTargets.forEach((element) => {
      element.classList.add("hidden");
    });

    if (this.idValue) {
      localStorage.setItem(this.name(), false);
    }
  }
}
